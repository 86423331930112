import React, { useState, useEffect, useRef, useCallback } from 'react';
import config from '../../config';
import { getLastSegmentInUrl } from "../../utils";
import { debounce } from 'icos-cp-utils';
export default function PreviewSelfContained({ preview, iframeSrcChange }) {
    const iframeRef = useRef(null);
    const [height, setHeight] = useState(() => getInitialHeight(preview.type));
    const handleResize = useCallback(debounce(() => {
        if (iframeRef.current) {
            updateHeight(iframeRef.current);
        }
    }), []);
    const handleKeydown = (event) => {
        if (event.target instanceof HTMLInputElement)
            return;
        iframeRef.current?.contentWindow?.postMessage({ keydown: event.key });
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        document.addEventListener("keydown", handleKeydown);
        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("keydown", handleKeydown);
        };
    }, [handleResize, handleKeydown]);
    const updateHeight = (iframe) => {
        if (shouldUpdateHeight(preview.type) && iframe.contentWindow) {
            setHeight(iframe.contentWindow.document.body.scrollHeight + 25);
        }
    };
    const handleLoad = (event) => {
        iframeSrcChange(event);
        setTimeout(() => updateHeight(event.target), 300);
    };
    if (!preview?.type) {
        return null;
    }
    const src = getPreviewIframeUrl(preview.type, preview.item);
    return (React.createElement("div", { className: "row", style: { height } },
        React.createElement("iframe", { ref: iframeRef, onLoad: handleLoad, src: src, loading: "lazy" })));
}
function getInitialHeight(previewType) {
    switch (previewType) {
        case config.NETCDF: return Math.max(window.innerHeight - 100, 480);
        case config.MAPGRAPH: return 1100;
        case config.PHENOCAM: return 1100;
        default: return 600;
    }
}
function shouldUpdateHeight(previewType) {
    switch (previewType) {
        case config.NETCDF: return false;
        case config.PHENOCAM: return false;
        default: return true;
    }
}
function getPreviewIframeUrl(previewType, item) {
    const iFrameBaseUrl = config.iFrameBaseUrl[previewType];
    // Use preview.item.url if present since that one has all client changes recorded in history
    if (item.url) {
        return iFrameBaseUrl + getLastSegmentInUrl(item.url);
    }
    const hashId = getLastSegmentInUrl(item.dobj);
    if (previewType === config.PHENOCAM) {
        return `${iFrameBaseUrl}?objId=${hashId}`;
    }
    return iFrameBaseUrl + hashId;
}
